import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";

import "./index.css";
import Team from "./Team";
import aboutUsImg from "../../imgs/aboutus.svg";

export default function About() {
  return (
    <section id="about">
      <Row>
        <h1 className="line-wrap">
          <span>About US</span>
        </h1>
      </Row>
      <Container className="p-3 aboutus-container" fluid>
        <Row className="align-items-center">
          <Col md={6}>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Who we are</Accordion.Header>
                <Accordion.Body>
                  At ADL, we are not just another ordinary company – we are a
                  visionary team of dedicated individuals who live and breathe
                  innovation. We take pride in transforming ideas into reality
                  and delivering exceptional solutions that leave a lasting
                  impact. Our journey began with a shared dream, and today, it
                  has evolved into an inspiring tale of growth, collaboration,
                  and success.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Our mission</Accordion.Header>
                <Accordion.Body>
                  Our mission is to empower businesses with cutting-edge
                  technology solutions and streamlined payroll services,
                  allowing them to thrive in today's dynamic and digital
                  landscape. We are committed to helping our clients harness the
                  full potential of technology while ensuring their financial
                  processes run smoothly.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Our Values</Accordion.Header>
                <Accordion.Body>
                  <b>Innovation:</b> We embrace innovation to stay at the
                  forefront of technological advancements and bring the best
                  solutions to our clients.
                  <br />
                  <b>Integrity:</b> We operate with honesty, transparency, and
                  unwavering ethics in all our interactions and services.
                  <br />
                  <b>Customer-Centric:</b> Our clients are at the heart of
                  everything we do. We prioritize their needs and work
                  tirelessly to exceed their expectations.
                  <br />
                  <b>Excellence:</b> We strive for excellence in every project
                  and service we undertake, setting high standards for quality
                  and performance.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md={6}>
            <div className="image-container">
              <img src={aboutUsImg} alt="about us" />
            </div>
          </Col>
        </Row>
      </Container>
      <Team />
    </section>
  );
}
