import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import profileBackground from "../../imgs/profile_background.jpg";

const Team = () => {
  return (
    <Container className="mt-4 p-4">
      <Row className="justify-content-center">
        <Col md={7} className="text-center">
          <h3 className="mb-3">Experienced & Professional Team</h3>
          <h6 className="subtitle font-weight-normal">
            You can relay on our amazing features list and also our customer
            services will be great experience for you without doubt and in
            no-time
          </h6>
        </Col>
      </Row>
      <Row className="justify-content-center p-4">
        <Col md={4} data-aos="flip-right">
          <div className="card profile-card">
            <div className="background-block">
              <img
                src={profileBackground}
                alt="profile-top"
                className="background"
              />
            </div>
            <div className="profile-thumb-block">
              <img
                src="https://bootdey.com/img/Content/avatar/avatar3.png"
                alt="profile"
                className="profile"
              />
            </div>
            <div className="card-content">
              <h2>
                Padma Lingamaneni<span>15+ Exp. in IT industry</span>
                <small>Advisory board member</small>
              </h2>
              <div className="icon-block">
                <a
                  href="https://www.linkedin.com/in/padma-lingamaneni-172787a0/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col md={4} data-aos="flip-right">
          <div className="card profile-card">
            <div className="background-block">
              <img
                src={profileBackground}
                alt="profile-top"
                className="background"
              />
            </div>
            <div className="profile-thumb-block">
              <img
                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                alt="profile"
                className="profile"
              />
            </div>
            <div className="card-content">
              <h2>
                Chalapathi R Bellam<span>18+ Exp. in IT industry</span>
                <small>Advisory board member</small>
              </h2>
              <div className="icon-block">
                <a
                  href="https://www.linkedin.com/in/chalapathi-r-bellam-524b3014/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col md={4} data-aos="flip-right">
          <div className="card profile-card">
            <div className="background-block">
              <img
                src={profileBackground}
                alt="profile-top"
                className="background"
              />
            </div>
            <div className="profile-thumb-block">
              <img
                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                alt="profile"
                className="profile"
              />
            </div>
            <div className="card-content">
              <h2>
                Mahesh G<span>22+ Exp. in IT industry</span>
                <small>Advisory board member</small>
              </h2>
              <div className="icon-block">
                <a
                  href="https://www.linkedin.com/in/mahesh-gogineni-b651885/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Team;
