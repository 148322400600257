import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";

import ITConsultingImage from "../../imgs/it-consulting.jpg";
import PayRollImage from "../../imgs/payroll.jpg";
import resourceImage from "../../imgs/resource-providing.jpg";


export default function Services() {
  return (
    <section id="services">
      <Container>
        <Row className="section-header">
          <h1 className="line-wrap">
            <span>Our Services</span>
          </h1>
        </Row>
        <Row className="p-4 justify-content-center ">
          <Col md={4} className="d-flex">
            <div className="card flex-fill card-hover">
              <img src={ITConsultingImage} alt="IT Consulting" className="card-img-top" width={300} height={300}/>
              <div className="card-body">
                <h5 className="card-title">IT Consulting</h5>
                <p className="card-text">
                  Our IT consulting services empower businesses to leverage
                  cutting-edge technology for streamlined operations and
                  enhanced productivity.
                </p>
              </div>
            </div>
          </Col>
          <Col md={4} className="d-flex">
            <div className="card flex-fill card-hover">
            <img src={PayRollImage} alt="IT Consulting" className="card-img-top" width={300} height={300}/>
              <div className="card-body">
                <h5 className="card-title">Payroll Services</h5>
                <p className="card-text">
                  Efficient and accurate payroll services ensure your employees
                  are paid on time, every time, while you focus on growing your
                  business.
                </p>
              </div>
            </div>
          </Col>
          <Col md={4} className="d-flex">
            <div className="card flex-fill card-hover">
            <img src={resourceImage} alt="IT Consulting" className="card-img-top" width={300} height={300}/>
              <div className="card-body">
                <h5 className="card-title">Resource Providing</h5>
                <p className="card-text">
                  We specialize in resource providing, connecting you with top
                  talent to meet your project needs and drive success.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
