import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { Container, Row, Col } from "react-bootstrap";

import "./index.css";

const Footer = () => {
  return (
    <section className="footer">
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center p-3">
            <h4>Follow us on</h4>
            <div className="social-media-icons">
              <div className="icon">
                <a
                  href="https://www.linkedin.com/company/adlit-consulting/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    style={{ color: "black" }}
                  />
                </a>
              </div>
              <div className="icon">
                <a
                  href="https://www.instagram.com/adlitconsulting"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ color: "black" }}
                  />
                </a>
              </div>
              <div className="icon">
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    style={{ color: "black" }}
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col md={6} className="p-3">
            <div className="news-letter">
              <h4>News Letter</h4>
              <p>
                Subcribe our newsletter and stay up to date about the company &
                hirings
              </p>
            </div>
            <div className="form-group">
              <Row>
                <Col md={8}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                  />
                </Col>
                <Col md={4}>
                  <button className="btn btn-primary">Subscribe</button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="text-center">
          <p>© 2023 ADL IT Consulting.</p>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
