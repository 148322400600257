import React from "react";

import CustomNavbar from "./Navbar";
import CustomCarousel from "../Carousel";

const Header = ({activeSection}) => {
  return (
  <section id="home">
    <CustomNavbar activeSection={activeSection}/>
    <CustomCarousel />
  </section> );
};

export default Header;
