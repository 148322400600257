import React from "react";
import { Row, Col } from "react-bootstrap";

const CarouselItem = ({ item }) => {
  return (
    <>
      <div className="carousel-container">
        <Row className="slide">
          <Col md={6} className="slide-info">
            <h1>{item.title}</h1>
            <p>{item.subTitle}</p>
          </Col>
          <Col
            md={6}
            className="slide-img"
            style={{
              background: `url(${item.imageSrc})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed"
            }}
          >
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CarouselItem;
