import { Button, Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faLaptopCode,
  faPaperPlane,
  faBuildingUser,
} from "@fortawesome/free-solid-svg-icons";

import { faLinkedinIn, faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";

import logo from "../../imgs/ADL.png";
import "./index.css";

const CustomNavbar = ({ activeSection }) => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="fixed-top"
      style={{ background: "#ccc" }}
    >
      <Container>
        <Navbar.Brand href="#home">
          <img alt="" src={logo} className="d-inline-block align-top logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#home" active={activeSection === "home"}>
              <FontAwesomeIcon icon={faHouse} /> Home
            </Nav.Link>
            <Nav.Link href="#services" active={activeSection === "services"}>
              <FontAwesomeIcon icon={faLaptopCode} /> Services
            </Nav.Link>
            <Nav.Link href="#about" active={activeSection === "about"}>
              <FontAwesomeIcon icon={faBuildingUser} /> About US
            </Nav.Link>
            <Nav.Link href="#contact" active={activeSection === "contact"}>
              <FontAwesomeIcon icon={faPaperPlane} /> Contact US
            </Nav.Link>
            <Nav.Link href="#services" active={activeSection === "services"}>
              <Button className="btn btn-primary">Hire us</Button>
            </Nav.Link>
          </Nav>
          <Nav className="flex-row">
            <Nav.Link
              href="https://www.linkedin.com/company/adlit-consulting/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="navbar-social-icon">
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                />
              </div>
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/adlitconsulting/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="navbar-social-icon">
                <FontAwesomeIcon
                  icon={faInstagram}
                />
              </div>
            </Nav.Link>
            <Nav.Link
              href="https://www.facebook.com"
              target="_blank"
              rel="noreferrer"
            >
              <div className="navbar-social-icon">
                <FontAwesomeIcon
                  icon={faFacebookF}
                />
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
