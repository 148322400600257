import React, { useEffect, useState } from "react";
import Aos from "aos";
import 'aos/dist/aos.css';

import "./App.css";
import Header from "./components/Header/Header";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  const [activeSection, setActiveSection] = useState("home");
  // Function to handle scroll and update the active section
  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    let active = "";

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2) {
        active = section.id;
      }
    });

    setActiveSection(active);
  };

  // Add a scroll event listener to trigger the scroll detection
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    Aos.init();
  }, [])
  return (
    <div>
      <Header activeSection={activeSection}/>
      <Services />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
