import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMapLocationDot,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";

import "./index.css";
import CustomAlert from "../Alert";

export default function Contact() {
  const formRef = useRef();
  const [show, setShow] = useState(false);

  const alertClose = () => {
    formRef.current.reset();
    setShow(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_i454goe",
        "template_7licttv",
        formRef.current,
        "T1fRZgS-NKHPDExRY"
      )
      .then(
        (result) => {
          if (result.status === 200 && result.text === "OK") {
            setShow(true);
            formRef.current.reset();
            setTimeout(() => {
              setShow(false);
            }, 1000);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact" className="contact">
      <Container>
        <Row>
          <h1 className="line-wrap">
            <span>Contact US</span>
          </h1>
        </Row>
        <Row className="contact-main">
          <Col md={4} className="p-4">
            <div className="info">
              <div className="phone ">
                <span className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <h4>Phone: </h4>
                <p> +91 95023 81397</p>
              </div>
              <div className="email">
                <span className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <h4>Email: </h4>
                <p>
                  <a href="mailto:support@adlitconsulting.in">
                    support@adlitconsulting.in
                  </a>
                </p>
              </div>
              <div className="address">
                <span className="icon">
                  <FontAwesomeIcon icon={faMapLocationDot} />
                </span>
                <h4>Address: </h4>
                <p> Brick skywoods, Hyderabad, TS - 500046</p>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <CustomAlert {...{ show, variant: "success", alertClose }} />
            <form ref={formRef} onSubmit={sendEmail}>
              <Row>
                <Col md={6} className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="user_name"
                    required
                  />
                </Col>
                <Col md={6} className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-mail"
                    name="user_mail"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    name="user_phone"
                    required
                  />
                </Col>
                <Col md={6} className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    name="subject"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col className="form-group mt-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    rows={5}
                    name="message"
                    required
                  ></textarea>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 4, offset: 5 }}>
                  <button type="submit" className="btn btn-primary btn-send">
                    Send Message
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
