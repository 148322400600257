import Alert from "react-bootstrap/Alert";

function CustomAlert({ show, variant, alertClose }) {
  return (
    <>
      <Alert
        show={show}
        variant={variant}
        onClose={() => alertClose()}
        dismissible
      >
        <p>Your message send successfully</p>
        <hr />
      </Alert>
    </>
  );
}

export default CustomAlert;
