import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselItem from "./carouseltem";
import "./index.css";
import connect_Img from '../../imgs/connect.png';
import commit_Img from '../../imgs/commit.jpg';
import clear_Img from '../../imgs/clear.jpg'

const CustomCarousel = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false
  };

  const carouselItems = [
    {
      id: 1,
      imageSrc: connect_Img,
      title: "Connect",
      subTitle: "Reach out and connect with the team.",
    },
    {
      id: 2,
      imageSrc: clear_Img,
      title: "Clear",
      subTitle: "Clear any doubts you may have",
    },
    {
      id: 3,
      imageSrc: commit_Img,
      title: "Commit",
      subTitle: "Demonstrate commitment towards your goals",
    },
  ];

  return (
    <Slider {...settings}>
      {carouselItems.map((item, index) => (
        <CarouselItem item={item} />
      ))}
    </Slider>
  );
};

export default CustomCarousel;
